import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
// import HomeIcon from '@mui/icons-material/Home';
// import WhatshotIcon from '@mui/icons-material/Whatshot';
import StorefrontIcon from '@mui/icons-material/Storefront';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { grey } from '@mui/material/colors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// function handleClick(event) {
//     event.preventDefault();
//     // console.info('You clicked a breadcrumb.');
// }

export default function IconBreadcrumbs({previous, current}) {
    // console.log(previous, current)
  return (
    <div role="presentation" >

        <Breadcrumbs aria-label="breadcrumb" 
        separator={<ArrowForwardIosIcon fontSize="small" />}
        sx={{
            mt: '1rem',
                "& ol": {
                    justifyContent: "center",
                    margin: "auto"
                  }
        }}>
            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href="/"
            >
                <HomeOutlinedIcon sx={{ mr: 0.5, fontSize: {xs: 20, md: 22}, color: grey[500]}}/>
                <Typography sx={{lineHeight: 3, fontSize: 14, fontFamily: 'Neuton'}}>{previous}</Typography>
            </Link>

            { current !== undefined ?             
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/shop"
                    >
                    <StorefrontIcon sx={{ mr: 0.5, fontSize: {xs: 20, md: 22}, color: grey[400]}}/>
                    <Typography sx={{lineHeight: 3, fontSize: 14, fontFamily: 'Neuton'}}>{current}</Typography>
                </Link> 
                : 
                <></>
            }


        </Breadcrumbs>
    </div>
  );
}
import React from "react"
import { Typography, Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';


function TitleBlock({Name, Size, Location, Variety}) {

    return(
        <>
            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                width: '100%'
                }}>
                <Typography 
                    textAlign={'left'} 
                    gutterBottom
                    sx={{
                        fontSize: {xs: 28, md: 32}, 
                        lineHeight: 1.5,
                        fontFamily: 'Neuton', 
                        fontWeight: 400,
                        color: grey[800],
                        }}>
                    {Name}
                </Typography>
            </Box>

            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                mt: '1rem',
                }}>
                <Typography 
                    textAlign={'left'} 
                    gutterBottom
                    sx={{
                        fontSize: {xs: 20, md: 24}, 
                        lineHeight: 1.5,
                        fontFamily: 'Neuton', 
                        fontWeight: 300,
                        }}>
                    {Size}
                </Typography>
            </Box>

            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                mt: '1rem'
                }}>
                <PinDropOutlinedIcon />
                <Typography 
                    gutterBottom
                    sx={{
                        fontSize: {xs: 20, md: 24}, 
                        lineHeight: 1.5,
                        ml: '0.5rem',
                        fontFamily: 'Neuton', 
                        fontWeight: 200}}>
                        {Location}
                </Typography>
            </Box>

            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                mt: '1rem',
                }}>
                <Typography 
                    textAlign={'left'} 
                    gutterBottom
                    sx={{
                        fontSize: {xs: 20, md: 24}, 
                        lineHeight: 1.5,
                        fontFamily: 'Neuton', 
                        fontWeight: 200,
                        }}>
                    Olive Variety: {Variety}
                </Typography>
            </Box>
        </>
    )
}

export default TitleBlock
import React, {useState} from "react";
import { Box, Grid, TextField, Alert } from "@mui/material";
// import { grey, red, green } from "@mui/material/colors";
// // import { InlineWidget } from "react-calendly";
// // import { PopupWidget } from "react-calendly";
// // import { PopupButton } from "react-calendly";
// import { useTheme } from '@mui/material/styles';
import { functions } from "../firebase";
import {httpsCallable} from "firebase/functions";
import { useFormik } from 'formik';
import * as yup from 'yup';
import ColorButton from "./coloredButton";

// ALI:
// CHECK SNACKBAR INSTEAD OF ALERT.

async function handleMessage(message, name) {

    const addMail = httpsCallable(functions, 'addmessage')

    addMail({msg: message, name: name})
    .then((result) => {
        console.log(result.data)
        console.log(result.data.text)
    })
    .catch((error) => {
        const code = error.code;
        const message = error.message;
        const details = error.details;
      })
}


export default function Emailform() {

    const [loading, setLoading] = useState(false)

    const addMail = httpsCallable(functions, 'addmessage')

    const validationSchema = yup.object({
        name: yup.string().required('Name is required'),
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        msg: yup
            .string()
            .required('Your message is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            msg: '',
        },
        initialErrors: {
            name: 'Name',
            email: 'Email',
            msg: 'Message',
        },
        validateOnMount: false,
        validationSchema: validationSchema,
        handleChange: () => {},
        onSubmit: (values, {resetForm}) => {
            setLoading(true)
            addMail({
                name: values.name, 
                email: values.email, 
                msg: values.msg
            })
            .then()
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
            })
            resetForm();
        }

      });

    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column',
            width: '90%',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            fontFamily: 'Neuton',
            mt: '2%',
            }}>

            <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {loading && <Alert 
                        // variant="outlined"
                        onClose={() => {}}
                        severity="success">We have recieved your inquery and will in touch soon!</Alert>}
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{}}>
                        <Box 
                            sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            }}>
                            <TextField
                                fullWidth
                                sx={{fontFamily: 'Neuton',}}
                                required
                                id="email"
                                name="email"
                                label="Email"
                                color='secondary'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{}}>
                        <Box 
                            sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            }}>
                            <TextField
                                fullWidth
                                required
                                id="name"
                                name="name"
                                label="Name"
                                color='secondary'
                                variant='outlined'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}/>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{}}>
                        <Box 
                            sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            }}>
                            <TextField
                                fullWidth
                                required
                                multiline
                                rows={3}
                                id="msg"
                                name="msg"
                                label="Message"
                                color='secondary'
                                value={formik.values.msg}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.msg && Boolean(formik.errors.msg)}
                                helperText={formik.touched.msg && formik.errors.msg} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{}}>
                        <Box 
                            sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            }}>
                            <ColorButton
                                disableElevation
                                disabled={!(formik.isValid)}
                                type='submit'
                                variant='contained'
                                sx={{
                                    fontFamily: 'Neuton',
                                    fontSize: 16,
                                    fontWeight: 400,
                                    width: {xs: '100%', sm: 'auto'}
                                    }}>
                                Submit Inquary
                            </ColorButton>
                        </Box>
                    </Grid>

                </Grid>
                
            </form>

        </Box>
    )
}
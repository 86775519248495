import React from "react";
import Layout from "../components/layout";
import Emailform from "../components/emailForm";
import { Box, Container, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
// import { InlineWidget } from "react-calendly";
// import { PopupWidget } from "react-calendly";
// import { PopupButton } from "react-calendly";
import PageHeader from "../components/pageHeader";
import { Helmet } from 'react-helmet';

function Contactpage() {

    return (
        <div>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content={'The pantry catering and bakery contact page'} />
                <meta name="keywords" content="novello, oilive oil, novello olive oil, italian olive oil, pantry, pantry bakery, pantry catering, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch, executive breakfast, corporate event, fresh, lunch box" />

                <meta property="og:title" content={'Contact Us'} />
                <meta property="og:description" content={'The pantry catering and bakery contact page'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://thepantry.catering/contact'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Contact Us'}  />
                <meta name="twitter:description" content={'The pantry catering and bakery contact page'} />
            </Helmet>
            <Layout>
                <Container maxWidth='md'>
                    
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '85vh',
                        pt: '6rem',
                        }}>

                        <PageHeader title={'Contact Us'} />

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            mt: '5%', 
                            }}>
                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    color: grey[700], 
                                    fontSize: {xs: 18, md:20},
                                    fontFamily: 'Neuton', 
                                    width: '100%'}}>
                                Need more information? We'd love to hear from you! We are here to answer any and all questions you might have. Our direct support hours are Monday-Friday, 9:00 am to 4:00 pm (Western). We’ll be in touch with you as soon as we can.
                            </Typography>
                        </Box>
                        
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            mt: '5%', 
                        }}>
                            <Emailform />
                        </Box>

                    </Box>

                </Container>
            </Layout>
        </div>
    )
}

export default Contactpage

    // {/* <InlineWidget url="https://calendly.com/the-pantry" 
    // styles={{
    //     maxHeight: '600px',
    //     width: '100%',
    // }}

    // pageSettings={{
    //     backgroundColor: 'ffffff',
    //     hideEventTypeDetails: false,
    //     hideLandingPageDetails: false,
    //     primaryColor: '00a2ff',
    //     textColor: '4d5055'
    // }}
    // /> */}

    // {/* <Divider variant='middle' sx={{width: '100%'}}/> */}

    // {/* <PopupWidget
    //     url="https://calendly.com/the-pantry"
    //     rootElement={document.getElementById("root")}
    //     text="Schedule a free Meeting!"
    //     textColor={'white'}
    //     textSize={28}
    //     color={grey[500]}
    //     /> */}

    // {/* <Divider variant='middle' sx={{width: '100%'}}/> */}

    // {/* <PopupButton
    //     url="https://calendly.com/the-pantry"
    //     rootElement={document.getElementById("root")}
    //     text="Schedule Free Cosultation Meeting"
    //     styles={{
    //         color: 'white',
    //         fontFamily: 'inherit',
    //         fontSize: 20,
    //         background: grey[500],
    //         lineHeight: 3,
    //         borderRadius: 7,
    //         borderWidth: 0,
    //         marginTop: '2%',
    //     }}/> */}
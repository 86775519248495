import React from "react"
import { Typography, Box, TextField } from "@mui/material";
import EastIcon from '@mui/icons-material/East';

function NewsLetter() {

    return(
        <Box sx={{width: {xs: '95%', md: '80%'}}}>
            <Typography 
                textAlign={'left'}
                sx={{
                    lineHeight: 2,
                    fontFamily: 'Neuton',
                    marginBottom: '16px',
                    fontSize: {xs: 20, md: 20}
                    }}>
                Your journey with Pantry starts here
            </Typography>

            <Box sx={{display: 'flex', alignItems: 'center', width: '100%', height: '100%'}}>

                <Box sx={{display: 'flex', alignItems: 'center', width: '80%', height: '100%', marginBottom: '16px',}}>
                    <TextField 
                        variant='standard'
                        fullWidth 
                        required 
                        label="Email"
                        InputLabelProps={{
                            sx: {
                            fontFamily: 'Neuton',
                            fontSize: 16,
                            }
                        }} 
                        />
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20%', height: '100%', }}>
                    <EastIcon fontSize='small' />
                </Box>
            </Box>

            <Typography
                textAlign={'left'}
                sx={{
                    lineHeight: 1.5,
                    fontFamily: 'Neuton',
                    marginBottom: '16px',
                    fontWeight: 300,
                    fontSize: {xs: 16, md: 12}
                    }}>
                Exciting news, exclusive events, & delicious products: subscribe to our emails to hear the latest 
                from Pantry's.
            </Typography>

            {/* <Typography                                    
                textAlign={'left'}
                sx={{
                    lineHeight: 2,
                    fontFamily: 'Neuton',
                    marginBottom: '16px',
                    fontSize: {xs: 24, md: 20}
                    }}>
                Follow US
            </Typography> */}

        </Box>
    )
}

export default NewsLetter
import React from "react";
import Layout from "../components/layout";
// import FeaturedProduct from "../components/featured-product";
// import { ProductsContext } from "../context/products-context";
import { 
    Typography, 
    Box, 
    Container,
    // Button, 
    // Grid 
} from "@mui/material";
import { Helmet } from 'react-helmet';
import { grey } from "@mui/material/colors";


export default function About() {

    return(
        <div>
            <Helmet>
                <title>About Pantry</title>
                {/* NOTE: HAS TO BE CHANGED */}
                <meta name="description" content={'affordable private chef, and catering for events'} />
                <meta name="keywords" content="novello, oilive oil, novello olive oil, italian olive oil, best in OC, best in LA, affordable private chef, catering, private event, baby shower, engagement, corporate dining" />

                <meta property="og:title" content={'Welcome to The Pantry'} />
                <meta property="og:description" content={'affordable private chef, and catering for events'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://thepantry.catering'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Welcome to The Pantry'} />
                <meta name="twitter:description" content={'affordable private chef, and catering for events'} />
            </Helmet>
            <Layout>
                <Container maxWidth='lg'>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center', 
                        width: '100%',
                        pt: '6rem',
                        }}>

                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontFamily: 'Neuton',
                                fontSize: {xs: 36, md: 48},
                                lineHeight: 2,  
                                fontWeight: 400,
                                color: grey[800], 
                                mt: '1rem',
                                mb: '1rem',
                                width: '100%', 
                                height: '100%'}}>
                            About Us
                        </Typography>

                        <Typography 
                            textAlign={'justify'} 
                            sx={{
                                fontFamily: 'Neuton',
                                fontSize: {xs: 18, md: 20},
                                lineHeight: {xs: 1.5, md: 2},
                                fontWeight: 300, 
                                color: grey[900],
                                mt: '1rem',
                                mb: '1rem',
                                width: '100%', 
                                height: '100%'}}>
                                At The Pantry Catering, we believe that food is more than just nourishment—it’s an experience that brings 
                                people together, sparks joy, and creates lasting memories. Founded with a mission to deliver thoughtfully 
                                crafted, high-quality food, we specialize in event catering, corporate catering, and curated lunch boxes, 
                                offering everything from artisanal sandwiches to vibrant grazing spreads that elevate any occasion.
                        </Typography>

                        <Typography 
                            textAlign={'justify'} 
                            sx={{
                                fontFamily: 'Neuton',
                                fontSize: {xs: 18, md: 20},
                                lineHeight: {xs: 1.5, md: 2},
                                fontWeight: 300, 
                                color: grey[900],
                                mt: '1rem',
                                mb: '1rem',
                                width: '100%', 
                                height: '100%'}}>
                                Our journey began with a simple idea: to combine exceptional flavor with mindful sourcing. Every dish we create 
                                reflects our commitment to using locally produced, sustainable ingredients that not only taste incredible but also 
                                support our local community and reduce our environmental impact. We partner with local farmers, artisans, and 
                                small-scale producers who share our values, ensuring that each ingredient meets our high standards of quality, 
                                freshness, and sustainability.
                        </Typography>

                        <Typography 
                            textAlign={'justify'} 
                            sx={{
                                fontFamily: 'Neuton',
                                fontSize: {xs: 18, md: 20},
                                lineHeight: {xs: 1.5, md: 2},
                                fontWeight: 300, 
                                color: grey[900],
                                mt: '1rem',
                                mb: '1rem',
                                width: '100%', 
                                height: '100%'}}>
                                At The Pantry Catering, we understand that every event is unique. Whether it’s a corporate meeting, a large-scale event, 
                                or a more intimate gathering, we work closely with our clients to design customized menus that reflect their vision and 
                                needs. From individually curated lunch boxes that are perfect for office gatherings to elaborate grazing tables that 
                                impress and delight, we ensure that every detail is thoughtfully executed.
                        </Typography>

                        <Typography 
                            textAlign={'justify'} 
                            sx={{
                                fontFamily: 'Neuton',
                                fontSize: {xs: 18, md: 20},
                                lineHeight: {xs: 1.5, md: 2},
                                fontWeight: 300, 
                                color: grey[900],
                                mt: '1rem',
                                mb: '1rem',
                                width: '100%', 
                                height: '100%'}}>
                                We also take pride in creating food that not only looks beautiful but also tells a story—one of heritage, sustainability, 
                                and a love for celebrating life’s moments. Our dishes are crafted to be more than just meals—they are experiences designed 
                                to connect people and foster a sense of community.
                        </Typography>

                        <Typography 
                            textAlign={'justify'} 
                            sx={{
                                fontFamily: 'Neuton',
                                fontSize: {xs: 18, md: 20},
                                lineHeight: {xs: 1.5, md: 2},
                                fontWeight: 300, 
                                color: grey[900],
                                mt: '1rem',
                                mb: '1rem',
                                width: '100%', 
                                height: '100%'}}>
                                As a minority woman-owned business with an Asian background, we bring a diverse perspective to the table, blending 
                                cultural influences and modern techniques to create menus that are both innovative and rooted in tradition. This unique 
                                combination allows us to offer a catering experience that is fresh, flavorful, and unlike any other.
                        </Typography>

                        <Typography 
                            textAlign={'justify'} 
                            sx={{
                                fontFamily: 'Neuton',
                                fontSize: {xs: 18, md: 20},
                                lineHeight: {xs: 1.5, md: 2},
                                fontWeight: 300, 
                                color: grey[900],
                                mt: '1rem',
                                mb: '1rem',
                                width: '100%', 
                                height: '100%'}}>
                                At The Pantry Catering, we believe that great food is at the heart of every celebration. Whether you’re hosting a corporate 
                                event, planning a special celebration, or simply looking for an elevated lunch experience, we’re here to provide food that 
                                delights your guests, nourishes your body, and celebrates the present moment.
                        </Typography>

                        <Typography 
                            textAlign={'justify'} 
                            sx={{
                                fontFamily: 'Neuton',
                                fontSize: {xs: 18, md: 20},
                                lineHeight: {xs: 1.5, md: 2},
                                fontWeight: 300, 
                                color: grey[900],
                                mt: '1rem',
                                mb: '1rem',
                                width: '100%', 
                                height: '100%'}}>
                                We’re more than just a catering service—we’re a partner in creating unforgettable experiences, one meal at a time.
                        </Typography>

                    </Box>

                </Container>
            </Layout>
        </div>
    )
}
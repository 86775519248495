import {Routes, Route} from 'react-router-dom';
import { 
    createTheme, 
    ThemeProvider, 
} from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Helmet } from 'react-helmet';
import Homepage from './pages/home';
import NotFound from './components/not-found';
import Shop from './pages/shop';
import CartPage from './pages/cart-page';
import Success from './pages/success';
import About from './pages/about';
import Deliveries from './pages/deliveries';
import Contactpage from './pages/contact';
import PantryItem from './components/pantryItem';
import './App.css';

const theme = createTheme({
    palette: {
        primary: {
            // main: '#aeae00',
            main: '#f3ede0', 
            light: '#dcdc00',
            dark: '#808000',
        //   light: '#757ce8',
            // main: '#3f50b5',
            // main: '#F8F6F1' ---> This is original color
            // main: '#FFFFFF',
        //   dark: '#002884',
        //   contrastText: '#fff',
        },
        secondary: {
        //   light: '#ff7961',
            // main: '#f44336',
            main: grey[800],
        //   dark: '#ba000d',
        //   contrastText: '#000',
        },
        info: {
            // main: yellow['600'],
            main: grey[900],
            dark: '#8a8a5f',
        },
        warning: {
            main: grey[800],
        },
        action: {
            // main: grey[800],
            main: '#8a8a5f',
        },
        ochre: {
            main: '#808000',
            // light: '#E9DB5D',
            // dark: '#A29415',
            // contrastText: '#242105',
        },
    },
    overrides: {
    //     // For label
        MuiCard: {
          root: {
            "& .hidden-button": {
              display: "none"
            },
            "&:hover .hidden-button": {
              display: "flex"
            }
          }
        }
    }
})


function App() {
    
    return (
        <ThemeProvider theme={theme}>
        <div className="App">                
            <Helmet>
                    {/* <title>Welcome to The Pantry Bakery & Catering</title> */}
                    <meta 
                        name="description" 
                        content={'The pantry bakery and catering provides unique cateringa nd culinary experience centered around locally sourced, fresh, and organic ingredients in Los Angeles, Irvine counties in California.'} />
                    <meta 
                        name="keywords" 
                        content="novello, oilive oil, novello olive oil, italian olive oil, pantry, pantry bakery, pantry catering, event catering, plant-based catering, organic catering, authentic korean food catering, low fat lunch, low sodium lunch box, corporate catering, corporate lunch box, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch catering, executive breakfast, corporate event catering, fresh, special event lunch box" />

                    <meta property="og:title" content={'Welcome to The Pantry Bakery and Catering'} />
                    <meta property="og:description" content={'The pantry bakery and catering provides unique cateringa nd culinary experience centered around locally sourced, fresh, and organic ingredients in Los Angeles, Irvine counties in California.'} />
                    <meta property="og:url" content={'https://thepantry.catering'} />
                    <meta property="og:type" content="website" />
                </Helmet>
            <Routes>

                <Route exact path='/' Component={Homepage} />
                <Route path='/cart' Component={CartPage} />
                <Route path='/shop' Component={Shop} />
                <Route path='/product/:id' Component={PantryItem} />
                <Route path='/about' Component={About} />
                <Route path='/contact' Component={Contactpage} />
                <Route path='/delivery-information' Component={Deliveries} />
                <Route path='/success' Component={Success} />
                <Route path='*' Component={NotFound} />

            </Routes>
        </div>
        </ThemeProvider>
    );
}

export default App;

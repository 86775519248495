import React , { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { CartContext } from "../context/cart-context";
import { 
    Typography, 
    Box, 
    Container,
} from "@mui/material";
import Layout from '../components/layout';
// import { Helmet } from 'react-helmet';
import { grey } from "@mui/material/colors";
// import { useSearchParams } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
// import { functions } from "../firebase/index";
// import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";

import { getFunctions, httpsCallable } from "firebase/functions";
import ColorButton from "../components/coloredButton";
import PantryProgress from "../components/pantryCircularProgress";

function Success() {
    
    const functions = getFunctions();
    const retrieve = httpsCallable(functions, "retrieve");
    const navigate = useNavigate()

    const [email, setEmail] = useState()
    const [cName, setCName] = useState()
    const [loading, setLoading] = useState(true)
    

    useEffect( () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('session_id')

        retrieve(id)
        .then((result) => {
          // Handle the result
          console.log(result)
          setCName(result.data.customer.name)
          setEmail(result.data.customer.email)
          setLoading(false)

        })
        .catch((error) => {
          // Handle the error
        //   console.log(error)
        });
    }, [retrieve])
  

    return(

            <Layout>

                <Container maxWidth='lg'>

                     {
                        loading ? 
                    
                        <PantryProgress />

                        :

                        <Box sx={{
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            }}>
                                <Box sx={{height: '10rem'}}></Box>
                                <Box sx={{
                                    display: 'flex', 
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center', 
                                    width: '100%',
                                    background: alpha('#F8F6F1', 0.6),
                                    pt: '8rem',
                                    // pb: '3rem',
                                    }}>

                                    <Box sx={{
                                        width: '100%', 
                                        alignItems: 'center', 
                                        justifyContent: 'center', }}>

                                        <Typography 
                                            textAlign={'center'} 
                                            sx={{
                                                fontFamily: 'Neuton',
                                                fontSize: {xs: 36, md: 48},
                                                lineHeight: 2,  
                                                fontWeight: 400,
                                                color: grey[800], 
                                                // mt: '8rem',
                                                // mb: '8rem',
                                                width: '100%', 
                                                height: '100%'}}>
                                            Thanks for your order, {cName}!
                                        </Typography>

                                    </Box> 

                                    <Box sx={{
                                        width: '100%', 
                                        alignItems: 'center', 
                                        justifyContent: 'center', }}>

                                        <Typography 
                                            textAlign={'center'} 
                                            sx={{
                                                fontFamily: 'Neuton',
                                                fontSize: {xs: 24, md: 36},
                                                lineHeight: 2,  
                                                fontWeight: 400,
                                                color: grey[800], 
                                                mt: '4rem',
                                                mb: '2rem',
                                                width: '100%', 
                                                height: '100%'}}>
                                            A confirmation email has been sent to:
                                        </Typography>

                                        <Typography 
                                            textAlign={'center'} 
                                            sx={{
                                                fontFamily: 'Neuton',
                                                fontSize: {xs: 20, md: 30},
                                                lineHeight: 2,  
                                                fontWeight: 300,
                                                color: grey[700], 
                                                mt: '2rem',
                                                mb: '8rem',
                                                width: '100%', 
                                                height: '100%'}}>
                                            {email}
                                        </Typography>

                                    </Box>          

                                </Box>
                                <Box sx={{
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '10rem', 
                                    width: '100%'}}>
                                    <ColorButton
                                        variant='contained' 
                                        disableElevation
                                        onClick={() => navigate("/shop")} 
                                        sx={{
                                            width: {xs: '100%', md: '50%'},
                                            mt: '1rem',
                                            mb: '1rem'
                                        }}>
                                        Return to Shop
                                    </ColorButton>
                                </Box>
                        </Box>

                    }

                </Container>

            </Layout>
    )
}

export default Success
import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Box } from "@mui/material";

const Layout = ({children}) => {

    return(
        <>
        {/* <Container  */}
        <Box
            // {/* maxWidth='md'
            sx={{
                // display: 'flex',
                // flexDirection: 'column',
                // alignItems: 'center',
                // background: '#F8F6F1'
                // background: '#FFFFFF' Original color we chose
                background: '#f3ede0'
            }}
            >
            <Header />
                {
                    children
                }
            
        {/* </Container> */}
        </Box>
        <Footer />
        </>
    )
}

export default Layout
import React from "react";

import { 
    Box, 
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

export default function PantryProgress() {

    const theme = useTheme()

    return(
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '85vh'}}>
            <CircularProgress size={'4rem'} thickness={4} sx={{color: theme.palette.primary.dark}}/>
        </Box>
    )
}
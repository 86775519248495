import React from "react";
import Layout from "./layout";
import { Box, Typography } from "@mui/material";

const NotFound = () => {
    return(
        <Layout>
            <Box alignItems={'center'} justifyContent={'space-evenly'} sx={{height: '80vh'}}>
                <Typography variant="h5" textAlign={'center'} sx={{mt: '12rem', mb: '12rem'}}>
                    Unfortunately, we cannot find that page!
                </Typography>
                {/* <Button>
                    Home
                </Button> */}
            </Box>
        </Layout>
    )
}

export default NotFound
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Box, Typography, AppBar, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from "@mui/material/colors";
// import InstagramIcon from '@mui/icons-material/Instagram';
import { useTheme } from '@mui/material/styles';
import Cart from "./cart";

function Header() {

    const theme = useTheme()

    const [anchorElNav, setAnchorElNav] = useState(null)
    const open = Boolean(anchorElNav)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    return(
        <AppBar 
            position="fixed"
            style={{width: '100%', 
                background: theme.palette.primary.main, 
                boxShadow: 'none'
            }}>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center' ,
                width: '100%',
                height: '2rem',
                background: theme.palette.info.dark,
                }}>
                <Typography                 
                    textAlign={'center'}
                    sx={{
                        lineHeight: 2, 
                        fontSize: 16, 
                        fontWeight: 400,
                        color: 'white',
                        fontFamily: 'Neuton',
                        letterSpacing: 2,
                        }}>
                    FREE SHIPPING ON ALL ORDERS OVER $150
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'center',
                width: '100%',
                height: '4rem',
                }}>

                <Box sx={{
                    display:{xs: 'flex', md: 'none'},
                    width: {xs: '20%', md: '25%'}, 
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '4rem',
                    }}>

                    <IconButton size='large' onClick={handleOpenNavMenu}>
                        <MenuIcon color={ grey['900'] }/>
                    </IconButton>
                    <Menu 
                        open={open} 
                        onClose={handleCloseNavMenu} 
                        anchorEl={anchorElNav} 
                        sx={{
                            "& .MuiPaper-root": {
                                backgroundColor: theme.palette.primary.main,
                            }
                        }}>

                    <MenuItem onClick={handleCloseNavMenu}>
                        <Link 
                            to={'/about'}
                            style={{
                                textDecoration: 'none', 
                                "&:hover": {
                                    textDecoration: 'underline',
                                }
                            }}>
                            <Typography 
                                textAlign={'justify'}
                                sx={{
                                    fontSize: {xs: 18, md: 20},
                                    fontFamily: 'Neuton',
                                    fontWeight: 500, 
                                    color: grey[900]
                                    }}>
                                About
                            </Typography>
                        </Link>
                    </MenuItem>

                    <MenuItem onClick={handleCloseNavMenu}>
                    <span com='true' style={{
                            textDecoration: 'none', 
                            "&:hover": {
                                textDecoration: 'underline',
                                }
                            }}>
                            
                            <Typography 
                                textAlign={'justify'}
                                sx={{
                                    fontSize: {xs: 18, md: 20},
                                    fontFamily: 'Neuton',
                                    fontWeight: 500, 
                                    color: grey[600]
                                    }}>
                                Account
                            </Typography>
                        </span>
                    </MenuItem>

                    <MenuItem onClick={handleCloseNavMenu}>
                        <Link 
                            to={'/shop'} 
                            style={{
                                textDecoration: 'none', 
                                "&:hover": {
                                    textDecoration: 'underline',
                                    }
                                }}>
                            <Typography 
                                textAlign={'justify'}
                                sx={{
                                    fontSize: {xs: 18, md: 20},
                                    fontFamily: 'Neuton',
                                    fontWeight: 500, 
                                    color: grey[900]
                                    }}>
                                Shop
                            </Typography>
                        </Link>
                    </MenuItem>
                    </Menu>
                </Box>

                <Box sx={{
                    display:{xs: 'flex', md: 'none'},
                    width: {xs: '60%', md: '25%'}, 
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '4rem',
                    }}>
                    <Button component={Link} to="/" sx={{pl:{md: '0.75rem'}, '&.MuiButton-root:hover':{bgcolor: 'transparent'}}} disableRipple={true}>
                        <img 
                            src={require("../assets/trans.png")} 
                            alt="The pantry catering and bakery logo"
                            // height={'100%'}
                            width={275}
                            />
                    </Button>
                </Box>

                <Box sx={{
                    display:{xs: 'flex', md: 'none'},
                    width: {xs: '20%', md: '25%'}, 
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '4rem',
                    }}>
                    <Box sx={{
                        display: 'flex', 
                        // width: {md: '10%'}
                        }}>
                        <Cart />
                    </Box>
                </Box>

                <Box sx={{
                        display: {xs: 'none', md: 'flex'},
                        width: { md: '40%'}, 
                        alignItems: 'center',
                        justifyContent: {xs: 'center', md: 'left'},
                        }}>
                    <Button component={Link} to="/" sx={{pl:{md: '0.75rem'}, '&.MuiButton-root:hover':{bgcolor: 'transparent'}}} disableRipple={true}>
                        <img 
                            src={require("../assets/trans.png")} 
                            alt="The pantry catering and bakery logo"
                            // height={'100%'}
                            width={300}
                            />
                    </Button>
                </Box>

                <Box sx={{
                    display: {xs: 'none', md: 'flex'},
                    width: {xs: '20%', md: '60%'}, 
                    alignItems: 'center',
                    // background: 'blue',
                    mr: {xs: '2.5rem', md: '2rem'},
                    justifyContent:{xs: 'right'}}}>

                    <Box sx={{
                        display:{xs: 'none', md: 'flex'},
                        alignItems: 'center',
                        justifyContent: 'center',
                        // background: 'green', 
                        width: '15%'}}>

                        <Link 
                            to={'/about'}
                            style={{
                                textDecoration: 'none', 
                                "&:hover": {
                                    textDecoration: 'underline',
                                }
                            }}>
                            <Typography 
                                textAlign={'justify'}
                                sx={{
                                    fontSize: {xs: 18, md: 20},
                                    fontFamily: 'Neuton',
                                    fontWeight: 500, 
                                    color: grey[900]
                                    }}>
                                About
                            </Typography>
                        </Link>
                    </Box>

                    <Box sx={{
                        display:{xs: 'none', md: 'flex'}, 
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '25%'}}>

                        <span style={{
                            textDecoration: 'none', 
                            "&:hover": {
                                textDecoration: 'underline',
                                }
                            }}>
                            
                            <Typography 
                                textAlign={'justify'}
                                sx={{
                                    fontSize: {xs: 18, md: 20},
                                    fontFamily: 'Neuton',
                                    fontWeight: 500, 
                                    color: grey[600]
                                    }}>
                                Account
                            </Typography>
                        </span>

                    </Box>

                    <Box sx={{
                        display:{xs: 'none', md: 'flex'}, 
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '15%',}}>

                        <Link 
                            to={'/shop'} 
                            style={{
                                textDecoration: 'none', 
                                "&:hover": {
                                    textDecoration: 'underline',
                                    }
                                }}>
                            <Typography 
                                textAlign={'justify'}
                                sx={{
                                    fontSize: {xs: 18, md: 20},
                                    fontFamily: 'Neuton',
                                    fontWeight: 500, 
                                    color: grey[900]
                                    }}>
                                Shop
                            </Typography>
                        </Link>

                    </Box>

                    <Box sx={{
                        display: 'flex', 
                        width: {md: '10%'}
                        }}>
                        <Cart />
                    </Box>
                    
                </Box>

            </Box>

        </AppBar>
    )
}

export default Header
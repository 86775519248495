const storeCartItems = (cartItems) => {
    // localStorage.removeItem('cart')
    const cart = cartItems.length > 0 ? cartItems : []
    localStorage.setItem('cart', JSON.stringify(cart))
}

export const sumItems = cartItems => {
    storeCartItems(cartItems)
    // console.log(cartItems)
    return {
        itemCount: cartItems.reduce((total, prod) => total + prod.quantity, 0),
        // total: cartItems.reduce((total, prod) => console.log(prod), 0)
        total: cartItems.reduce(
            (total, prod) => total + (prod.product.default_price.unit_amount * 0.01 * prod.quantity), 
            0)
    }
}

const cartReducer = (state, action) => {
    switch(action.type) {
        case 'ADD_ITEM':

            if (!state.cartItems.find(item => item.product.id === action.payload.id)) {

                state.cartItems.push({
                    product: action.payload,
                    quantity: 1,
                })
            }
            return {
                ...state,
                cartItems: [...state.cartItems],
                ...sumItems(state.cartItems)
            }
        case 'INCREASE': 
            const increaseIndex = state.cartItems.findIndex(item => item.product.id === action.payload.product.id)
            state.cartItems[increaseIndex].quantity++
            return {
                ...state,
                cartItems: [...state.cartItems],
                ...sumItems(state.cartItems)
            }
        case 'DECREASE': 
            const decreaseIndex = state.cartItems.findIndex(item => item.product.id === action.payload.product.id)
            const product = state.cartItems[decreaseIndex]
            if (product.quantity > 1) {
                product.quantity--
            }
            return {
                ...state,
                cartItems: [...state.cartItems],
                ...sumItems(state.cartItems)
            }
        case 'REMOVE_ITEM': 
            const newCartItem = state.cartItems.filter(item => item.product.id !== action.payload.id)
            // const newCartItem = state.cartItems.filter(item => item.subid !== action.payload)
            return {
                ...state,
                cartItems: [...newCartItem],
                ...sumItems(newCartItem)
            }
        case 'CLEAR':
            localStorage.removeItem('cart')
            return {
                cartItems: [],
                itemCount: 0,
                total: 0
            }
        default:
            return state
    }
}

export default cartReducer
// import { auth } from './firebase/index'

export const isInCart = (new_product, cartItems) => {

    if (cartItems.length === 0) {
        return null
    } else {
        return cartItems.find(item => item.id === new_product.id)
    }
}

// const API = 'http://localhost:8080';

// export async function fetchfromAPI(endpoint, opts) {

//     const {method, body} = {method: 'POST', body: null, ...opts}
//     const user = auth.currentUser
//     const token = user && (await user.getIdToken())
//     const res = await fetch(`${API}/${endpoint}`, {
//         method,
//         ...(body && {body: JSON.stringify(body)}),
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`
//         },
//     })

//     if (res.status === 200) {
//         return res.json()
//     } else {
//         throw new Error(res.statusText)
//     }
// }
// import React from "react";
import { Button } from "@mui/material";
// import { grey, yellow } from "@mui/material/colors";
// import { alpha } from "@mui/material/styles";
import { styled } from '@mui/material/styles';
// import { useTheme, alpha } from '@mui/material/styles';

// const theme = useTheme()

const ColorButton = styled(Button)(({ theme }) => ({
    // color: theme.palette.getContrastText(yellow[600]),

    lineHeight: 2,
    // backgroundColor: '#A79B60',
    // borderColor: yellow[600],
    // border: '1px solid', 
    // borderRadius: '2rem',
    fontSize: 14,
    fontWeight: 400,
    // background: '#8a8a5f',
    background: theme.palette.info.dark, 
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    fontFamily: 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      // color: theme.palette.getContrastText(theme.palette.primary.dark),
      lineHeight: 2,
      fontWeight: 600,
      // border: '1.5px solid',
      // borderColor: grey[800]
    },
  }));

export default ColorButton
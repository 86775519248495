import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React  from "react";
import { Link } from "react-router-dom";
// import { grey, lightGreen, yellow } from "@mui/material/colors";
// import { styled } from '@mui/material/styles';
// import CheckOut from "../stripe/checkoutStripe";
import { useStripe, } from '@stripe/react-stripe-js';
import ColorButton from './coloredButton';
import { useTheme, alpha } from '@mui/material/styles';
import { getFunctions, httpsCallable } from "firebase/functions";

const Total = (props) => {

    const {cartItems, itemCount, total, clearCart} = props

    const stripe = useStripe()

    const theme = useTheme()

    const functions = getFunctions();
    const checkout = httpsCallable(functions, "checkout");

    return(
        <Card variant='outlined' sx={{background: alpha(theme.palette.primary.light, 0.1)}}>

            {/* <CardHeader 
                disableTypography
                title={<Typography textAlign={'center'} sx={{fontSize: 30, fontFamily:'Blaster', fontWeight: 700}}>Summary</Typography>} 
                // titleTypographyProps={{variant: 'h5', textAlign: 'center', fontWeight: 'bold', }}
                /> */}

            <CardContent>

                <Grid container direction={{xs: 'column', sm: 'row'}} spacing={{xs: 2}}>

                    <Grid item xs={12} sm={6}>

                        <Box sx={{
                            width: '100%', 
                            height: '100%', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'space-evenly',}}>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography textAlign={'center'} sx={{fontSize: {xs: 16, md: 20}, fontFamily: 'Neuton', fontWeight: 500}}>
                                    Items:
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography textAlign={'center'} sx={{fontSize: {xs: 16, md: 20}, fontFamily: 'Neuton', fontWeight: 600}}>
                                    {itemCount}
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography textAlign={'center'} sx={{fontSize: {xs: 16, md: 20}, fontFamily: 'Neuton', fontWeight: 500}}>
                                    Total:
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography textAlign={'center'} sx={{fontSize: {xs: 16, md: 20}, fontFamily: 'Neuton', fontWeight: 600}}>
                                    ${Math.round(100 * total) / 100}
                                </Typography>
                            </Box>

                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        { itemCount > 0 && 
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', height: '100%', }}>
                                <ColorButton
                                    variant='contained' 
                                    disableElevation
                                    // disabled={total > 150 ? false : true}
                                    // disabled={minOrder(cartItems, total)}
                                    // to={'/https://buy.stripe.com/eVa7us5qg945fni9AA'}
                                    onClick={(e) => {
                                        // Original
                                        // CheckOut(cartItems, itemCount, total)
                                        // .then( res => {
                                        //     const session_Id = res.id
                                        //     stripe.redirectToCheckout({sessionId: session_Id})
                                        //     })
                                        // NEWWWW
                                        checkout({cartItems: cartItems, itemCount: itemCount, total: total})
                                        .then( res => {
                                            // console.log(res)
                                            const session_Id = res.data.id
                                            stripe.redirectToCheckout({sessionId: session_Id})
                                            })
                                        }} 
                                    sx={{
                                        width: {xs: '45%', sm: 'auto'}, 
                                        maxwidth: '49%', 
                                        pl: 2, 
                                        pr: 2
                                        }}>
                                    CHECKOUT
                                </ColorButton>

                                <ColorButton 
                                    variant='contained' 
                                    disableElevation
                                    onClick={() => clearCart()} 
                                    sx={{
                                        width: {xs: '45%', sm: 'auto'}, 
                                        maxwidth: '49%', 
                                        pl: 2, 
                                        pr: 2
                                        }}>
                                    CLEAR CART
                                </ColorButton>
                            </Box>
                        }
                        { itemCount === 0 &&
                            <Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                <ColorButton 
                                    variant='contained' 
                                    disableElevation
                                    component={Link} 
                                    to='/shop' 
                                    sx={{
                                        width: '80%', 
                                        fontFamily: 'Neuton', 
                                        fontSize: {xs: 12, md: 16}
                                        }}>
                                    GO TO SHOP
                                </ColorButton>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Total
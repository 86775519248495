import React from "react"
import { Typography, Box } from "@mui/material";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";


function NewsLetter2() {

    const theme = useTheme()

    return(
        <Box sx={{xs: '95%', md: '80%'}}>
            <Box sx={{display: 'flex', alignItems: 'center', width: '100%', height: '100%'}}>
                <LocalShippingOutlinedIcon sx={{mr: '2%'}}/>
                <Typography 
                    textAlign={'left'}
                    sx={{
                        lineHeight: 2.5,
                        fontFamily: 'Neuton',
                        // marginBottom: '16px',
                        fontSize: {xs: 24, md: 20}
                        }}>
                    Delivery:
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                width: '100%', 
                height: '100%'
                }}>

                <Typography 
                    textAlign={'left'}
                    sx={{
                        lineHeight: {xs: 1.5, md: 2.5},
                        fontFamily: 'Neuton',
                        fontWeight: 400,
                        color: grey[800],
                        // marginBottom: '16px',
                        fontSize: {xs: 18, md: 20}
                        }}>
                    Note: This item is on pre-order and is expected to start shipping after December 15, 2024.
                </Typography>

            </Box>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                width: '100%', 
                height: '100%',
                mt: '1rem',
                }}>

                <Typography
                    // noWrap
                    textAlign={'left'}
                    sx={{
                        lineHeight: {xs: 1.5, md: 2.5},
                        fontFamily: 'Neuton',
                        marginBottom: '16px',
                        fontWeight: 300,
                        fontSize: {xs: 14, md: 16}
                        }}>
                    This product can be shipped to 48 continental States, Canada and selected countries around the world.
                </Typography>

            </Box>

            <Box sx={{display: 'flex', alignItems: 'center', width: '100%', height: '100%'}}>
                <Link 
                    to={'/delivery-information'}
                    style={{
                        // textDecoration: 'none', 
                        color: theme.palette.primary.dark,
                        "&:hover": {
                            textDecoration: 'underline',
                        }
                    }}>
                    <Typography
                        textAlign={'left'}
                        sx={{
                            lineHeight: 1.5,
                            fontFamily: 'Neuton',
                            marginBottom: '16px',
                            fontWeight: 300,
                            fontSize: {xs: 16, md: 16}
                            }}>
                        Delivery Information and Charges
                    </Typography>
                </Link>


                {/* <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20%', height: '100%', }}>
                    <EastIcon fontSize='small' />
                </Box>
                </Box> */}

            </Box>

        </Box>
    )
}

export default NewsLetter2
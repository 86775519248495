import React, {useContext} from "react";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import { Badge, IconButton } from "@mui/material";
import { CartContext } from "../context/cart-context";
import { Link } from "react-router-dom";
// import { useTheme } from "@emotion/react";

const Cart = () => {

    // const theme = useTheme()

    const { itemCount } = useContext(CartContext)

    return(
        <div>
            <IconButton component={Link} to={'/cart'} disableRipple={true}>
                <Badge badgeContent={itemCount} color='ochre'>
                {/* <Badge badgeContent={itemCount}> */}
                    <ShoppingCartOutlinedIcon color='warning'/>
                    {/* <ShoppingCartOutlinedIcon/> */}
                </Badge>
            </IconButton>
            {/* <IconButton color='black' sx={{m: 2}}>
                <ShoppingCartOutlinedIcon />
            </IconButton> */}
        </div>
    )
}

export default Cart
import { Typography, Box, IconButton, Divider } from "@mui/material";
import React from "react";
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import { grey } from "@mui/material/colors";
import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
import XIcon from '@mui/icons-material/X';
import { useTheme } from '@mui/material/styles';



function Footer() {

    const year = new Date().getFullYear()

    const theme = useTheme()

    return(

        <>
                
            <Box 
                borderTop={1}
                borderColor={theme.palette.primary.dark}
                sx={{
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#F3EDE0',
                    width: '100%',
                    // minHeight: '40vh',
                    // mb: {xs: '5rem', md: 0},
                }}>

                <Box 
                    sx={{
                        display: 'flex', 
                        flexDirection: {xs: 'column', md: 'row'},
                        alignItems: 'start',
                        justifyContent: 'center' ,
                        width: '100%',
                        height: '100%',
                        mt: {xs: '1rem', md: '2rem'}
                    }}>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center' ,
                        width: '100%',
                        }}>

                        <Typography 
                            textAlign={'center'}
                            sx={{
                                display: {xs: 'none', md: 'flex'},
                                fontSize: {xs: 18, md: 20}, 
                                fontWeight: 600,
                                color: grey[900],
                                fontFamily: 'Neuton',
                                mb: '0.5rem', 
                            }}>
                            Online Shopping
                        </Typography> 
                        
                        <Box sx={{width: '60%'}}>
                        <Divider flexItem variant='middle' sx={{display: {xs: 'none', md: 'flex'}}}/>
                        </Box>

                        <Box sx={{width: '80%'}}>
                        <Divider flexItem variant='middle' sx={{display: {xs: 'flex', md: 'none'}}}>
                            <Typography 
                                textAlign={'center'}
                                sx={{
                                    fontSize: {xs: 18, md: 20}, 
                                    fontWeight: 600,
                                    color: grey[900],
                                    fontFamily: 'Neuton',
                                    }}>
                                    Online Shopping
                            </Typography>
                        </Divider>
                        </Box>

                        <Box sx={{
                            display: 'flex', 
                            flexDirection: {xs: 'row', md: 'column'},
                            alignItems: 'center',
                            justifyContent: 'center' ,
                            width: '100%',
                            }}>

                            <Box sx={{width: '100%'}}>
                                <Typography 
                                    textAlign={'center'}
                                        sx={{
                                        lineHeight: 3, 
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300,
                                        color: grey[900],
                                        fontFamily: 'Neuton',
                                        }}>
                                    Returns Policy
                                </Typography> 
                            </Box>

                            <Box sx={{width: '100%'}}>
                                <Link 
                                    to={'/delivery-information'}
                                    style={{
                                        textDecoration: 'none', 
                                        "&:hover": {
                                            textDecoration: 'underline',
                                        }
                                    }}>
                                    <Typography 
                                        textAlign={'center'}
                                            sx={{
                                                lineHeight: 3, 
                                                fontSize: {xs: 14, md: 16}, 
                                                fontWeight: 300,
                                                color: grey[900],
                                                fontFamily: 'Neuton',
                                            }}>
                                            Shipping
                                    </Typography>
                                </Link>
                            </Box>

                            <Box sx={{width: '100%'}}>
                                <Typography 
                                    textAlign={'center'}
                                        sx={{
                                            lineHeight: 3, 
                                            fontSize: {xs: 14, md: 16}, 
                                            fontWeight: 300,
                                            color: grey[900],
                                            fontFamily: 'Neuton',
                                            }}>
                                        International Delivery
                                </Typography>
                            </Box>
                        </Box>
            
                    </Box>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%'
                        }}>

                        <Typography 
                            textAlign={'center'}
                            sx={{
                                display: {xs: 'none', md: 'flex'},
                                fontSize: {xs: 18, md: 20}, 
                                fontWeight: 600,
                                color: grey[900],
                                fontFamily: 'Neuton',
                                mb: '0.5rem'
                            }}>
                            Services
                        </Typography>

                        <Box sx={{width: '60%'}}>
                        <Divider flexItem variant='middle' sx={{display: {xs: 'none', md: 'flex'}}}/>
                        </Box>

                        <Box sx={{width: '80%'}}>
                        <Divider flexItem variant='middle' sx={{display: {xs: 'flex', md: 'none'}}}>
                            <Typography 
                                textAlign={'center'}
                                sx={{
                                    fontSize: {xs: 18, md: 20}, 
                                    fontWeight: 600,
                                    color: grey[900],
                                    fontFamily: 'Neuton',
                                    }}>
                                    Services
                            </Typography>
                        </Divider>
                        </Box>

                        <Box sx={{
                            display: 'flex', 
                            flexDirection: {xs: 'row', md: 'column'},
                            alignItems: 'center',
                            justifyContent: 'center' ,
                            width: '100%',
                            }}>

                            <Box sx={{width: '100%'}}>
                                <Typography 
                                    textAlign={'center'}
                                    sx={{
                                        lineHeight: 3, 
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300,
                                        color: grey[900],
                                        fontFamily: 'Neuton',
                                        }}>
                                    Corporate Gifting
                                </Typography> 
                            </Box>

                            <Box sx={{width: '100%'}}>
                                <Typography 
                                    textAlign={'center'}
                                    sx={{
                                        lineHeight: 3, 
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300,
                                        color: grey[900],
                                        fontFamily: 'Neuton',
                                        }}>
                                    Personal Shopping
                                </Typography>
                            </Box>

                            <Box sx={{width: '100%'}}>
                                <Typography 
                                    textAlign={'center'}
                                        sx={{
                                        lineHeight: 3, 
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300,
                                        color: grey[900],
                                        fontFamily: 'Neuton',
                                        }}>
                                </Typography>
                            </Box>

                        </Box> 
                    </Box>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%'
                        }}>

                        <Typography 
                            textAlign={'center'}
                            sx={{
                                display: {xs: 'none', md: 'flex'},
                                fontSize: {xs: 18, md: 20}, 
                                fontWeight: 600,
                                color: grey[900],
                                fontFamily: 'Neuton',
                                mb: '0.5rem',
                            }}>
                            Information
                        </Typography> 

                        <Box sx={{width: '60%'}}>
                        <Divider flexItem variant='middle' sx={{display: {xs: 'none', md: 'flex'}}} />
                        </Box>

                        <Box sx={{width: '80%'}}>
                        <Divider flexItem variant='middle' sx={{display: {xs: 'flex', md: 'none'}}}>
                            <Typography 
                                textAlign={'center'}
                                sx={{
                                    fontSize: {xs: 18, md: 20}, 
                                    fontWeight: 600,
                                    color: grey[900],
                                    fontFamily: 'Neuton',
                                    }}>
                                    Information
                            </Typography>
                        </Divider>
                        </Box>

                        <Box sx={{
                            display: 'flex', 
                            flexDirection: {xs: 'row', md: 'column'},
                            alignItems: 'center',
                            justifyContent: 'center' ,
                            width: '100%',
                            }}>

                            <Box sx={{width: '100%'}}>
                                <Link 
                                    to={'/contact'}
                                    style={{
                                        textDecoration: 'none', 
                                        "&:hover": {
                                            textDecoration: 'underline',
                                        }
                                    }}>

                                <Typography 
                                    textAlign={'center'}
                                        sx={{
                                        lineHeight: 3, 
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300,
                                        color: grey[900],
                                        fontFamily: 'Neuton',
                                        }}>
                                    Contact Us
                                </Typography> 
                                </Link>

                            </Box>

                            <Box sx={{width: '100%'}}>
                                <Typography 
                                    textAlign={'center'}
                                    sx={{
                                        lineHeight: 3, 
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300,
                                        color: grey[900],
                                        fontFamily: 'Neuton',
                                        }}>
                                    Press Office
                                </Typography>
                            </Box>

                            <Box sx={{width: '100%'}}>
                                <Typography 
                                    textAlign={'center'}
                                    sx={{
                                        lineHeight: 3, 
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300,
                                        color: grey[900],
                                        fontFamily: 'Neuton',
                                        }}>
                                    Stories
                                </Typography>
                            </Box>

                        </Box> 
                    </Box>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%'
                        }}>

                        <Typography 
                            textAlign={'center'}
                            sx={{
                                display: {xs: 'none', md: 'flex'},
                                fontSize: {xs: 18, md: 20}, 
                                fontWeight: 600,
                                color: grey[900],
                                fontFamily: 'Neuton',
                                mb: '0.5rem',
                                }}>
                                Connect
                        </Typography> 

                        <Box sx={{width: '60%'}}>
                        <Divider flexItem variant='middle' sx={{display: {xs: 'none', md: 'flex'}}} />
                        </Box>

                        <Box sx={{width: '80%'}}>
                        <Divider flexItem variant='middle' sx={{display: {xs: 'flex', md: 'none'}}}>
                            <Typography 
                                textAlign={'center'}
                                sx={{
                                    fontSize: {xs: 18, md: 20}, 
                                    fontWeight: 600,
                                    color: grey[900],
                                    fontFamily: 'Neuton',
                                    }}>
                                    Connect
                            </Typography>
                        </Divider>
                        </Box>

                        <Box sx={{
                            display: 'flex', 
                            flexDirection: {xs: 'row', md: 'column'},
                            alignItems: 'center',
                            justifyContent: 'center' ,
                            width: '100%',
                            }}>
                            <Box sx={{width: '100%', display: 'flex', alignItems: 'center',
                            justifyContent: 'center' ,}}>
                                <IconButton 
                                    disableRipple={false} 
                                    component={Link} 
                                    to={'http://www.instagram.com/thepantry.catering'}>
                                    <InstagramIcon />
                                </IconButton> 
                            </Box>
                            <Box sx={{width: '100%', display: 'flex', alignItems: 'center',
                            justifyContent: 'center' ,}}>
                                <IconButton 
                                    disableRipple={false} 
                                    component={Link} 
                                    to={'http://www.instagram.com/thepantry.catering'}>
                                    <FacebookIcon />
                                </IconButton>
                            </Box>
                            <Box sx={{width: '100%', display: 'flex', alignItems: 'center',
                            justifyContent: 'center'}}>
                                <IconButton 
                                    disableRipple={false} 
                                    component={Link} 
                                    to={'http://www.instagram.com/thepantry.catering'}>
                                    <XIcon />
                                </IconButton>
                            </Box>
                        </Box> 

                    </Box>

                </Box>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center' ,
                    width: '100%',
                    minHeight: {md: '5vh'},
                    background: theme.palette.info.dark,
                    mt: '2rem',
                    // mb: '2rem',
                    }}>
                    <Typography                 
                        textAlign={'center'}
                        sx={{
                            lineHeight: 2, 
                            fontSize: 16, 
                            fontWeight: 400,
                            color: grey[900],
                            fontFamily: 'Neuton',
                            }}>
                        The Pantry Bakery and Catering &copy;  2023 - {year}
                    </Typography>
                </Box>

            </Box>

        </>
    )
}

export default Footer
import React, { useEffect, createContext, useState } from "react";
// import { auth, createUserProfileDocument } from '../firebase';
import { setDoc, doc, getDoc, onSnapshot } from "firebase/firestore"
import { CircularProgress, Box } from "@mui/material";
import { auth, db } from '../firebase/index'
import { onAuthStateChanged } from "firebase/auth";

export const UserContext = createContext()

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        const unsubscribeFromAuth = onAuthStateChanged(auth, async (userAuth) => {
            if (userAuth) {
                const uid = userAuth.uid
                const userRef = doc(db, "users", uid)
                const docSnap = await getDoc(userRef)

                onSnapshot(userRef, snapShot => {
                    setUser({
                        id: snapShot.id,
                        ...snapShot.data()
                    })
                    setLoading(false)               
                })

            } else {
                setUser(userAuth)
                setLoading(false)
            }
        })
        return () => unsubscribeFromAuth();
    }, [])

    const userContext = {user, loading}

    if (loading) { 
        return (
            <Box sx={{ 
                display:'grid',                     
                justifyContent:'center', 
                height: '100vh', 
                alignContent: 'center' }}>
                <CircularProgress />
            </Box>
    )}

    return (
        <UserContext.Provider value={userContext}>
            {
                children
            }
        </UserContext.Provider>
    )
}

export default UserContextProvider
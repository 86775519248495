import React from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Card, Typography, CardMedia, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
// import CheckOut from "../stripe/checkoutStripe";

const CartItem = (props) => {

    const { product, quantity, increase, decrease, removeProduct } = props

    return(
        <Box sx={{display: 'flex', justifyContent: 'center'}}> 

            <Card variant={'outlined'} sx={{
                display: 'flex', 
                justifyContent: 'space-evenly', 
                alignContent: 'center', 
                mb: '2%',
                borderRadius: '0.25rem',
                background: grey[100],
                }}>

                <CardMedia
                    component={'img'}
                    sx={{width: '30%',}}
                    image={product.images[0]}
                    title={product.name}/>

                <Box sx={{
                    width: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    // mb: '2%'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography 
                                textAlign={'left'} 
                                sx={{
                                    ml: '5%', 
                                    fontSize: {xs: 18, md: 24}, 
                                    fontFamily: 'Neuton', 
                                    fontWeight: 500}}>
                                {product.name}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center'}}>
                            <Typography 
                                textAlign={'left'} 
                                sx={{
                                    ml: '5%', 
                                    fontSize: {xs: 16, md: 20}, 
                                    fontFamily: 'Neuton',
                                    color: grey[700], 
                                    fontWeight: 500}}>
                                {product.metadata.size}
                            </Typography>
                        </Box>

                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography 
                                textAlign={'left'} 
                                sx={{
                                    ml: '5%', 
                                    fontSize: {xs: 18, md: 20}, 
                                    fontFamily: 'Neuton', 
                                    fontWeight: 500}}>
                                Price: ${product.default_price.unit_amount * 0.01}
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                            }}>
                                { 
                                    quantity === 1 &&
                                    <Box sx={{display: 'flex', alignItems: 'start', justifyContent: 'center', width: '20%'}}>
                                        <IconButton onClick={
                                            () => removeProduct(product)
                                            }>
                                            <DeleteOutlineIcon/>
                                        </IconButton>        
                                    </Box>
                                } {
                                    quantity > 1 &&
                                    <Box sx={{display: 'flex', alignItems: 'start', justifyContent: 'center', width: '20%'}}>
                                        <IconButton onClick={() => decrease({product, quantity})}>
                                            <RemoveIcon/>
                                        </IconButton>
                                    </Box>
                                }

                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: {xs: '45%', sm: '35%', height: '100%'}}}>
                                    <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        ml: '5%', 
                                        fontSize: {xs: 18, md: 20}, 
                                        fontFamily: 'Neuton', 
                                        fontWeight: 500}}>
                                        Quantity: {quantity}
                                    </Typography> 
                                </Box>

                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '16%', height: '100%'}}>
                                    <IconButton onClick={() => increase({product, quantity})}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>

                                <Box sx={{width: '23%'}}>

                                </Box>

                        </Box>                                

                </Box>

            </Card>
        </Box>
    )
}

export default CartItem